import React from "react"
import { Menu } from "grommet"
import { MoreVertical } from "grommet-icons"
import { useUser } from "../contexts/user"
import { useLayers } from "../contexts/layers"
import { toast } from "react-toastify"
import {
  selectTaskById,
  useDeleteTaskMutation,
  useDeleteTaskOnTaskableMutation,
} from "../redux/tasksSlice"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useWindowWidth } from "../hooks/useWindowWidth"

const TaskRessourceMenu = ({
  item,
  relatable_id,
  relatable_type,
  refetch,
  label,
  isDetail = false,
}) => {
  const width = useWindowWidth()
  const isMobile = width < 600
  const navigate = useNavigate()
  const [, dispatch] = useLayers()
  const [user] = useUser()
  const task = useSelector(state => selectTaskById(state, item.id))
  const [deleteTask] = useDeleteTaskMutation()
  const [deleteWithTaskable] = useDeleteTaskOnTaskableMutation()

  const RESSOURCE = relatable_type === "offers" ? "datasets" : relatable_type

  let menu = []
  if (user.can(`task_update_${item.isOwnedBy(user) ? "own" : "others"}`)) {
    menu.push({
      label: <span data-cy={`update_task_on_${RESSOURCE}`}>Modifier</span>,
      onClick: () => {
        dispatch({
          type: "SHOW",
          id: item.id,
          relatable_id,
          relatable_type: RESSOURCE,
          component: relatable_type === null ? "SingleTask" : "Task",
          refetch,
        })
      },
    })
  }
  if (user.can(`task_delete_${item.isOwnedBy(user) ? "own" : "others"}`)) {
    menu.push({
      label: <span data-cy={`delete_task_on_${RESSOURCE}`}>Supprimer</span>,
      onClick: () => {
        if (window.confirm(`Vous allez supprimer la tâche`)) {
          const request =
            task.taskable_type === null
              ? deleteTask(task.id)
              : deleteWithTaskable(task)

          request
            .unwrap()
            .then(() => {
              toast.success("Supprimé")

              if (isDetail) {
                navigate(-1)
              }
            })
            .catch(() => {
              toast.error("Erreur")
            })
        }
      },
    })
  }

  return (
    menu.length > 0 && (
      <Menu
        data-cy="menu"
        icon={<MoreVertical />}
        dropAlign={{ top: "bottom", right: "right" }}
        items={menu}
        label={isMobile ? null : label}
      />
    )
  )
}

export default TaskRessourceMenu
