import React from "react"
import { Box, Grid, Heading, Text } from "grommet"
import { BackButton } from "./BackButton"
import { useWindowWidth } from "../hooks/useWindowWidth"

const RessourceTitle = ({
  suptitle,
  title,
  subtitle,
  buttons,
  action,
  back = false,
}) => {
  const width = useWindowWidth()
  const isMobile = width < 600
  const hasNav = back || suptitle

  return (
    <Grid
      columns={isMobile ? ["1fr", "45px"] : ["1fr", "1fr"]}
      rows={isMobile ? ["30px", "1fr"] : ["50px", "1fr"]}
      gap="small"
      margin={{ top: "small", bottom: "large" }}
      height={{ min: "50px" }}
      areas={[
        { name: "nav", start: [0, 0], end: [0, 0] },
        { name: "actions", start: [1, 0], end: [1, 0] },
        { name: "title", start: [0, 1], end: [1, 1] },
      ]}
    >
      {hasNav && (
        <Box gridArea="nav">
          <Box direction="row" gap="xsmall" margin={{ top: "small" }}>
            {back && <BackButton />}
            {back && suptitle && (
              <Text color="text-strong" size="18px">
                /
              </Text>
            )}
            {suptitle && (
              <Text color="text-strong" size="18px">
                {suptitle}
              </Text>
            )}
          </Box>
        </Box>
      )}
      <Box gridArea="actions" alignSelf="start">
        <Box align="center" direction="row" flex="grow" justify="end">
          {!isMobile && buttons && buttons.length > 0 && (
            <Box align="center" direction="row" gap="xxsmall">
              {buttons}
            </Box>
          )}
          {action}
        </Box>
      </Box>
      <Box gridArea={hasNav ? "title" : "nav"}>
        <Heading level="2" data-cy="page-title" margin="none">
          {title}
        </Heading>
        {subtitle && (
          <Text color="text-strong" size="18px">
            {subtitle}
          </Text>
        )}

        {isMobile && buttons && buttons.length > 0 && (
          <Box
            align="center"
            direction="row"
            gap="xxsmall"
            margin={{ top: "large" }}
          >
            {buttons.map(item => {
              return React.cloneElement(item, {
                size: "xsmall",
              })
            })}
          </Box>
        )}
      </Box>
    </Grid>
  )
}

export { RessourceTitle }
