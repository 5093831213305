import React from "react"
import { DataTable, Box, Button, Tab } from "grommet"
import { useLayers } from "../contexts/layers"
import TaskRessourceMenu from "./taskRessourceMenu"
import { useUser } from "../contexts/user"
import { PermissionLink } from "./PermissionLink"
import { useSelector } from "react-redux"
import { selectInstallationById } from "../redux/installationsSlice"

const TasksList = ({
  relatable_type,
  RESSOURCE,
  tasks = [],
  id,
  withTab = false,
  tabTitle = "Tâches",
  refetch,
  readOnly = false,
}) => {
  const [user] = useUser()
  const [, dispatch] = useLayers()
  const content = (
    <Box gap="small">
      {!readOnly && id && user.can("task_create") && (
        <div>
          <Button
            size="small"
            data-cy="add-task"
            label="Ajouter une tâche"
            onClick={() => {
              dispatch({
                type: "SHOW",
                component: "Task",
                relatable_id: id,
                relatable_type,
                refetch,
              })
            }}
          />
        </div>
      )}
      <div data-cy={`${RESSOURCE}_tasks`}>
        <div className="mobileTableWrapper">
          <DataTable
            className="mobileTable"
            data-cy={`table_tasks_on_${RESSOURCE}`}
            sort={{
              property: "starts_at",
              direction: "asc",
            }}
            columns={[
              {
                property: "id",
                primary: true,
                header: "Titre",
                sortable: false,
                render: item => <Title item={item} />,
              },
              {
                property: "status",
                header: "Statut",
                render: item => item.getStatus(),
              },
              {
                property: "starts_at",
                header: "Date de début",
                render: item => item.getStartDateHour(),
              },
              {
                property: "ends_at",
                header: "Date de fin",
                render: item => item.getEndDateHour(),
              },
              {
                property: "actions",
                sortable: false,
                render: item => {
                  if (!id || readOnly) {
                    return null
                  }
                  return (
                    <TaskRessourceMenu
                      {...{
                        item,
                        refetch,
                        relatable_id: id,
                        relatable_type,
                      }}
                    />
                  )
                },
              },
            ]}
            onSort={({ property, direction }) => {
              console.log(property, direction)
            }}
            data={tasks}
            fill
          />
        </div>
      </div>
    </Box>
  )

  if (withTab) {
    return (
      <Tab
        key="tasks"
        title={
          <>
            {tasks.length} {tabTitle}
          </>
        }
      >
        <Box pad={{ vertical: "medium" }}>{content}</Box>
      </Tab>
    )
  }

  return content
}

const Title = ({ item }) => {
  const installationId =
    item.taskable_type === "installation"
      ? item.taskable_id
      : item.taskable?.installation_id

  let city

  const installation = useSelector(state =>
    item.type === "repair" && installationId
      ? selectInstallationById(state, installationId)
      : null
  )
  if (installation) {
    city = installation.city

    if (installation.zip_code) {
      city = `${installation.zip_code} ${installation.city}`
    }
  }

  return (
    <PermissionLink className="link" to={item.getSlug()} permission="task_view">
      {item.type === "repair" ? installation.customer.name : item.getTitle()}
      <br />
      <small style={{ color: "#777" }}>{item.humanId}</small>
      {item.type === "repair" && (
        <div>
          <small style={{ color: "#777" }}>{city}</small>
        </div>
      )}
    </PermissionLink>
  )
}

export { TasksList }
