import {
  SelectInput,
  NumberInput,
  TextAreaInput,
  DateInput,
  ResourceInput,
  Jsx,
} from "./utils/Input"
import { TextColumn } from "./utils/Columns"
import { PermissionLink } from "../components/PermissionLink.jsx"
import { store } from "../store"
import {
  useAddMaintenanceMutation,
  useUpdateMaintenanceMutation,
  useDeleteMaintenanceMutation,
  selectAllMaintenances,
  selectMaintenanceById,
  useGetMaintenancesQuery,
} from "../redux/maintenancesSlice"
import { selectAllCustomers } from "../redux/customersSlice"
import Maintenance, {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_CANCELED,
} from "../models/maintenance"
import { selectCustomerInstallations } from "../redux/installationsSlice"
import { selectTaskableTasks } from "../redux/tasksSlice"
import { CommentsList, KdriveUrl, TasksList } from "../components"

export class MaintenanceRessource {
  static name = "maintenance"

  static permissionPrefix = "maintenance"

  static indexTitle = "Contrats de maintenance"

  static oneTitle = "une maintenance"

  static fuseConfig = [
    {
      name: "customer.name",
      weight: 3,
    },
    {
      name: "customer.city",
      weight: 3,
    },
    {
      name: "customer.zip_code",
      weight: 3,
    },
    {
      name: "humanId",
      weight: 3,
    },
  ]

  static methods = {
    load: useGetMaintenancesQuery,
    add: useAddMaintenanceMutation,
    update: useUpdateMaintenanceMutation,
    delete: useDeleteMaintenanceMutation,
  }

  static getById(id) {
    const item = selectMaintenanceById(store.getState(), id)
    return new Maintenance(item)
  }

  static all() {
    return selectAllMaintenances(store.getState())
  }

  static form(data = {}) {
    const customers = selectAllCustomers(store.getState()).map(c => ({
      value: c.id,
      label: c.getTitle(),
    }))

    const installations = selectCustomerInstallations(
      store.getState(),
      data?.customer_id
    ).map(c => ({ value: c.id, label: c.getTitle() }))

    return {
      fields: [
        new Jsx("kdrive")
          .label("Fichiers")
          .display(() => <KdriveUrl url={data.getDriveFolderUrl()} />)
          .get(),

        new Jsx("humanId")
          .label("Numéro de maintenance")
          .display(() => data.humanId)
          .get(),

        new ResourceInput("customer_id")
          .label("Client")
          .options(customers)
          .resourcePath("customers")
          .permission("customer_view")
          .searchable()
          .required()
          .get(),

        new ResourceInput("installation_id")
          .label("Installation")
          .options(installations)
          .resourcePath("installations")
          .permission("installation_view")
          .hidden(installations.length === 0)
          .get(),

        new SelectInput("status")
          .label("Statut")
          .required()
          .options([
            {
              value: STATUS_ACTIVE,
              label: "Actif",
            },
            {
              value: STATUS_INACTIVE,
              label: "Inactif",
            },
            {
              value: STATUS_CANCELED,
              label: "Annulé",
            },
          ])
          .get(),

        new DateInput("starts_at").label("Date de début").required().get(),

        new NumberInput("duration")
          .label("Durée de la tâche en heures")
          .labelOnDetail("Durée de la tâche")
          .display(value => `${value}h`)
          .required()
          .get(),

        new NumberInput("recurrence")
          .label("Récurrence en mois")
          .labelOnDetail("Récurrence")
          .display(value => `${value} mois`)
          .required()
          .get(),

        new DateInput("ends_at").label("Date d'expiration").get(),

        new TextAreaInput("notes").markdown().label("Notes").get(),
      ],
    }
  }

  static table() {
    return {
      columns: [
        new TextColumn("humanId")
          .label(() => (
            <>
              <div className="mobileTitle">Nom & prénom</div>
              <div className="desktopTitle">N° Contrat</div>
            </>
          ))
          .render(item => (
            <PermissionLink
              to={`/maintenances/${item.id}`}
              permission={`${this.permissionPrefix}_view`}
            >
              <div className="mobileTitle">
                {item.customer ? (
                  <>
                    <div>
                      {item.customer?.getTitle()} {item.customer?.getZipCity()}
                    </div>
                    <small style={{ color: "#777" }}>{item.humanId}</small>
                  </>
                ) : (
                  item.humanId
                )}
              </div>
              <div className="desktopTitle">{item.humanId}</div>
            </PermissionLink>
          ))
          .get(),

        new TextColumn("customer.name")
          .label("Client")
          .render(item => item?.customer?.getTitle())
          .get(),

        new TextColumn("zipcity").label("Localité").get(),

        new TextColumn("status")
          .label("Statut")
          .render(item => item.getStatusLabel())
          .get(),

        new TextColumn("start_at")
          .label("Date de début")
          .render(item => item.getStartDate())
          .get(),
      ],
    }
  }

  static tabs(data) {
    const tasks = selectTaskableTasks(store.getState(), "maintenance", data.id)

    return [
      {
        title: <>{tasks.length || 0} Tâches</>,
        content: (
          <TasksList
            {...{
              tasks,
              relatable_type: "maintenance",
              RESSOURCE: "maintenance",
              id: data.id,
            }}
          />
        ),
      },
      <>
        <CommentsList
          {...{
            relatable_type: "maintenance",
            RESSOURCE: "maintenance",
            id: data.id,
          }}
        />
      </>,
    ]
  }
}
