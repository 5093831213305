import React from "react"
import {
  Box,
  Button,
  DataTable,
  Form,
  Heading,
  Layer,
  Stack,
  Tab,
  Text,
} from "grommet"
import { useUser } from "../../contexts/user"
import { SearchField, RessourceMenu, AddButton } from "../../components"
import { useFiltersValues } from "../../hooks/useFiltersValues.jsx"
import { FieldResolver } from "../../components/FieldResolver"
import { Filter, FormClose } from "grommet-icons"
import { useLayers } from "../../contexts/layers.js"

const applyFilters = (values, filters) => {
  return values.filter(item => {
    for (const property in filters) {
      if (filters[property] === null || filters[property] === "") {
        continue
      }

      // if the filter being used is an array, we try to find items that matches all the elements
      if (Array.isArray(filters[property])) {
        for (let toFind of filters[property])
          if (!item[property].includes(toFind)) {
            return false
          }
      } else if (item[property] !== filters[property]) {
        return false
      }
    }
    return true
  })
}

function RessourceDataTable({
  resource,
  data,
  showSearchField,
  showFilters,
  inTab,
  showAddButton = false,
  defaultCreateValues,
}) {
  const [user] = useUser()
  const [, dispatch] = useLayers()
  const resourceTable = resource.table(data, user)
  const defaultFilters = {
    search: "",
    filters: {
      ...resourceTable?.defaultFilters,
    },
  }
  const [items, setItems] = React.useState(data)
  const [matchingItems, setMatchingItems] = React.useState(null)
  const [showFilterModal, setShowFilterModal] = React.useState(false)

  const { defaultValue, setSessionStorage } = useFiltersValues({
    initialValues: defaultFilters,
  })

  const [filters, setFilters] = React.useState(defaultValue.filters)
  const [searchFilter, setSearchFilter] = React.useState(defaultValue.search)

  /**
   * Handle fuzzy search changes
   */
  const handleSearch = React.useCallback(
    (result, search) => {
      const items = applyFilters(result, filters)
      setMatchingItems(items)
      setItems(items)
      setSessionStorage({
        search,
        filters,
      })
      setSearchFilter(search)
    },
    [setSessionStorage, filters]
  )

  /**
   * Handle filters changes
   */
  const handleFilterChange = React.useCallback(
    filters => {
      setSessionStorage({
        search: searchFilter,
        filters,
      })
      setFilters(filters)
      let baseData = data

      if (searchFilter && searchFilter.length > 2) {
        baseData = matchingItems
      }

      setItems(applyFilters(baseData, filters))
    },
    [setSessionStorage, searchFilter, data, matchingItems]
  )

  if (!inTab && !user.can(`${resource.permissionPrefix}_list`)) {
    return (
      <>
        Vous n'avez pas accès à cette ressource.
        <br />
        Si vous pensez qu'il s'agit d'une erreur, merci d'aviser votre
        administrateur.
      </>
    )
  }

  if (inTab && !user.can(`${resource.permissionPrefix}_list`)) {
    return null
  }

  const numberOfAppliedFilters = Object.values(filters).filter(x => {
    return x !== null && x !== "" && x.length > 0
  }).length

  const datatable = (
    <>
      {showAddButton && user.can(`${resource.permissionPrefix}_create`) && (
        <Box align="center" direction="row" flex="grow" justify="end">
          <AddButton
            onClick={() => {
              dispatch({
                type: "SHOW",
                component: "AddResource",
                resource: resource,
                data: defaultCreateValues,
              })
            }}
          />
        </Box>
      )}
      {(showSearchField || showFilters) && (
        <Box direction="row" align="center" gap="small" fill="horizontal">
          {showSearchField && resource.fuseConfig && (
            <SearchField
              data={data}
              index={resource.fuseConfig}
              value={searchFilter}
              onSearch={handleSearch}
            />
          )}
          {showFilters &&
            resourceTable.filters &&
            resourceTable.filters.length > 0 && (
              <div>
                <Button
                  onClick={() => {
                    setShowFilterModal(true)
                  }}
                >
                  <Stack anchor="top-right">
                    <Filter size="32px" />
                    {numberOfAppliedFilters > 0 && (
                      <Box
                        background="brand"
                        pad={{ horizontal: "5px", vertical: "1px" }}
                        round
                      >
                        <Text size="13px">{numberOfAppliedFilters}</Text>
                      </Box>
                    )}
                  </Stack>
                </Button>
              </div>
            )}
        </Box>
      )}
      {showFilters && numberOfAppliedFilters > 0 && (
        <Box margin={{ bottom: "medium" }}>
          <Text weight={700}>{items.length} résultats</Text>
        </Box>
      )}
      {showFilterModal && (
        <Layer
          modal
          onEsc={() => setShowFilterModal(null)}
          onClickOutside={() => setShowFilterModal(null)}
          responsive
        >
          <Box overflow={{ vertical: "auto" }} pad="medium" width="980px">
            <Box
              justify="between"
              align="center"
              direction="row"
              margin={{ bottom: "medium" }}
            >
              <Heading level={3} margin="none">
                Filtrer
              </Heading>
              <Button
                reverse
                plain
                label="Fermer"
                icon={<FormClose />}
                onClick={() => setShowFilterModal(null)}
              />
            </Box>
            <Form
              value={filters}
              onChange={handleFilterChange}
              onSubmit={({ value }) => {
                handleFilterChange(value)
                setShowFilterModal(false)
              }}
              onReset={() => {
                handleFilterChange({})
                setShowFilterModal(false)
              }}
            >
              <FieldResolver
                fields={resourceTable.filters}
                value={filters}
                setValue={setFilters}
              />
              <Box direction="row" justify="between">
                <Button type="reset" label="Réinitialiser" />
                <Button type="submit" label="Appliquer" primary />
              </Box>
            </Form>
          </Box>
        </Layer>
      )}
      <Box>
        {data.length === 0 ? (
          `Il n'y a pas encore de ${resource.indexTitle}`
        ) : (
          <div className="mobileTableWrapper">
            <DataTable
              step={data.length}
              className="mobileTable"
              columns={[
                ...resourceTable.columns,
                {
                  primary: true,
                  property: "id",
                  sortable: false,
                  render: item => (
                    <RessourceMenu
                      {...{
                        item,
                        layerComponent: "AddResource",
                        resource: resource,
                      }}
                    />
                  ),
                },
              ]}
              onSort={({ property, direction }) => {
                console.log(property, direction)
              }}
              data={items}
              fill
            />
          </div>
        )}
      </Box>
    </>
  )

  if (inTab) {
    return (
      <Tab
        title={
          <>
            {data.length} {resource.indexTitle}
          </>
        }
      >
        <Box pad={{ vertical: "medium" }}>{datatable}</Box>
      </Tab>
    )
  }

  return datatable
}

export { RessourceDataTable }
