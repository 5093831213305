import React from "react"
import checkRights from "../../hocs/checkRights.jsx"
import { useNavigate, useParams } from "react-router-dom"
import {
  NameValueList,
  NameValuePair,
  Box,
  Heading,
  Text,
  Tabs,
  Tab,
  Button,
} from "grommet"
import {
  TaskRessourceMenu,
  CommentsList,
  RessourceTitle,
} from "../../components"
import { useSelector } from "react-redux"
import { selectTaskable, selectTaskById } from "../../redux/tasksSlice.js"
import { selectEmployeeById } from "../../redux/employeesSlice.js"
import { selectSupplierById } from "../../redux/suppliersSlice.js"
import { Stopwatchable } from "../../components/Stopwatchable.jsx"
import { StopwatchesDataTable } from "../humanResources/stopwatches/datatable.jsx"
import { selectStopwatchableStopwatches } from "../../redux/HumanResources/stopwatchSlice.js"
import { PermissionLink } from "../../components/PermissionLink.jsx"
import { selectReportByTaskId } from "../../redux/Projects/reportsSlice.js"
import { ReportResource } from "../../resources/Projects/ReportResource.js"
import { selectInstallationById } from "../../redux/installationsSlice.js"

const RESSOURCE = "tasks"

const Employee = ({ id, index, total }) => {
  const employee = useSelector(state => selectEmployeeById(state, id))
  if (!employee) {
    return null
  }
  if (index < total && index !== 0) {
    return `, ${employee.name}`
  }

  return employee.name
}

const Supplier = ({ id, index, total }) => {
  const supplier = useSelector(state => selectSupplierById(state, id))
  if (!supplier) {
    return null
  }
  if (index < total && index !== 0) {
    return `, ${supplier.company_name}`
  }

  return supplier.company_name
}

const TaskDetailScreen = () => {
  const navigate = useNavigate()
  const { taskable_type, taskable_id, id } = useParams()
  ReportResource.methods.load()
  const [addReport] = ReportResource.methods.add()

  const task = useSelector(state => selectTaskById(state, id))
  const taskable = useSelector(state =>
    selectTaskable(state, taskable_type, taskable_id)
  )
  const stopwatches = useSelector(state =>
    selectStopwatchableStopwatches(state, "task", parseInt(id))
  )
  const report = useSelector(state => selectReportByTaskId(state, id))
  const installationId =
    taskable_type === "installation" ? taskable_id : taskable?.installation_id

  const installation = useSelector(state =>
    task?.type === 'repair' &&
      installationId ?
      selectInstallationById(state, installationId)
      : null
  )

  const taskableTitle = React.useMemo(() => {
    switch (taskable_type) {
      case "dataset":
      case "simple_offer":
        return "Offre"
      case "project":
        return "Projet"
      case "maintenance":
        return "Contrat"
      case "installation":
        return "Installation"
      default:
        return "En lien"
    }
  }, [taskable_type])

  const handleCreateReport = React.useCallback(() => {
    addReport({ task_id: task.id, installation_id: installationId })
      .unwrap()
      .then(response => navigate("/reports/" + response.report.id))
  }, [installationId, task?.id])

  const handleSeeReport = React.useCallback(() => {
    navigate("/reports/" + report.id)
  }, [report])

  if (!task) {
    return null
  }

  const isRepair = task.type === "repair"
  const isTaskInProject = taskable_type === "project"
  const buttons = []

  if (
    installationId &&
    !report &&
    (taskable_type === "maintenance" || isRepair)
  ) {
    buttons.push(
      <Button
        key="report"
        label="Créer un rapport"
        onClick={handleCreateReport}
      />
    )
  }

  if (report) {
    buttons.push(
      <Button key="report" label="Voir le rapport" onClick={handleSeeReport} />
    )
  }

  return (
    <>
      <RessourceTitle
        back
        suptitle={isRepair ? "Dépannages" : "Tâche"}
        title={isRepair && installation.customer ? installation.customer.name : task.getTitle()}
        buttons={buttons}
        action={
          <TaskRessourceMenu
            {...{
              item: task,
              relatable_id: taskable_id,
              relatable_type: taskable_type,
              label: "Actions",
              isDetail: true,
            }}
          />
        }
      />

      <div className="grid columns_2">
        <Box>
          <NameValueList>
            {taskable && (
              <NameValuePair name={taskableTitle}>
                <PermissionLink
                  className="link"
                  to={`/${taskable_type === "offers"
                    ? "offer"
                    : taskable_type === "simple_offer"
                      ? "simple-offer"
                      : taskable_type
                    }s/${taskable_id}`}
                  permission={`${taskable_type}_view`}
                >
                  {taskable.getTitle()}
                </PermissionLink>
              </NameValuePair>
            )}
            <NameValuePair name="Numéro de tâche">
              <Text color="text-strong">{task.humanId}</Text>
            </NameValuePair>
            <NameValuePair name="Status">
              <Text color="text-strong">{task.getStatus()}</Text>
            </NameValuePair>
            <NameValuePair name="Description">
              <Text color="text-strong">{task.description}</Text>
            </NameValuePair>
            <NameValuePair name="Collaborateurs">
              <Text color="text-strong">
                {task.assigned_employees.map((id, index) => (
                  <Employee
                    id={id}
                    index={index}
                    key={index}
                    total={task.assigned_employees.length}
                  />
                ))}
              </Text>
            </NameValuePair>
            <NameValuePair name="Fournisseurs">
              <Text color="text-strong">
                {task.assigned_suppliers.map((id, index) => (
                  <Supplier
                    id={id}
                    index={index}
                    key={index}
                    total={task.assigned_suppliers.length}
                  />
                ))}
              </Text>
            </NameValuePair>
            <NameValuePair name="Début">
              <Text color="text-strong">{task.getStartDateHour()}</Text>
            </NameValuePair>
            <NameValuePair name="Fin">
              <Text color="text-strong">{task.getEndDateHour()}</Text>
            </NameValuePair>
          </NameValueList>
        </Box>
        {!isTaskInProject && (
          <div>
            <Box
              background="light-1"
              pad="small"
              round="xsmall"
              margin={{ bottom: "medium" }}
              width={{ max: "360px" }}
            >
              <Heading level={4} margin={{ top: "none", bottom: "small" }}>
                Chronomètres
              </Heading>
              <Box gap="small">
                <Stopwatchable
                  stopwatchable_id={id}
                  stopwatchable_type="task"
                  type="main"
                />
                <Stopwatchable
                  stopwatchable_id={id}
                  stopwatchable_type="task"
                  type="transport"
                />
                <Stopwatchable
                  stopwatchable_id={id}
                  stopwatchable_type="task"
                  type="unplanned"
                />
              </Box>
            </Box>
          </div>
        )}
      </div>
      <Tabs alignControls="start" margin={{ top: "60px" }}>
        <>
          <CommentsList
            {...{
              relatable_type: "task",
              RESSOURCE,
              id,
            }}
          />
        </>
        {!isTaskInProject && (
          <Tab data-cy="chrono-tab" title={<>{"Chronomètres"}</>}>
            <Box pad={{ vertical: "medium" }}>
              <StopwatchesDataTable
                data={stopwatches}
                withStats
                stopwatchable_type="task"
                stopwatchable_id={id}
              />
            </Box>
          </Tab>
        )}
      </Tabs>
    </>
  )
}

const TaskDetail = checkRights(TaskDetailScreen, "task_view")

export { TaskDetail }
