import React from "react"
import { Box, Text, Heading, DataTable } from "grommet"
import { useSelector } from "react-redux"
import { useUser } from "../../../contexts/user"
import { selectTodayTasksByEmployeeId } from "../../../redux/tasksSlice"
import { TaskStopwatchMenu } from "../../../components/taskStopwatchMenu"
import { PermissionLink } from "../../../components/PermissionLink"

const TodayPanel = ({ employeeId }) => {
  const [user] = useUser()
  const employee_id = employeeId ? parseInt(employeeId) : user.employee_id

  const tasks = useSelector(state =>
    selectTodayTasksByEmployeeId(state, employee_id)
  )

  return (
    <Box background="light-1" pad="small" round="xsmall">
      <Box
        direction="row"
        gap="medium"
        align="center"
        margin={{ bottom: "small" }}
      >
        <Heading level={4} margin={{ top: "none", bottom: "none" }}>
          Aujourd'hui
        </Heading>
      </Box>
      {tasks.length === 0 ? (
        <Text>Il n'y a plus de tâches</Text>
      ) : (
        <div className="mobileTableWrapper">
          <DataTable
            className="mobileTable"
            columns={[
              {
                property: "id",
                primary: true,
                header: "Titre",
                sortable: false,
                render: item => (
                  <PermissionLink
                    className="link"
                    to={item.getSlug()}
                    permission="task_view"
                  >
                    {item.getTitle()}
                  </PermissionLink>
                ),
              },
              {
                property: "status",
                header: "Statut",
                render: item => item.getStatus(),
              },
              {
                property: "hours",
                header: "Horaire",
                render: item => item.getTodayIndications(),
              },
              {
                property: "actions",
                sortable: false,
                render: item => (
                  <TaskStopwatchMenu item={item} employee_id={employee_id} />
                ),
              },
            ]}
            data={tasks}
            fill
          />
        </div>
      )}
    </Box>
  )
}

export { TodayPanel }
