import Item from "./item"
import Customer from "./customer"
import Installation from "./installation"
import moment from "moment"
const { formatDate } = require("../utils/helper")

export const STATUS_ACTIVE = "active"
export const STATUS_INACTIVE = "inactive"
export const STATUS_CANCELED = "canceled"

export const STATUS_LABELS = {
  [STATUS_ACTIVE]: "Actif",
  [STATUS_INACTIVE]: "Inactif",
  [STATUS_CANCELED]: "Annulé",
}

export default class Maintenance extends Item {
  constructor(data) {
    super()
    Object.assign(this, data)
    this.humanId = "M-" + this.getId()
    this.starts_at = this.starts_at
      ? moment(this.starts_at).format("YYYY-MM-DD")
      : null
    this.ends_at = this.ends_at
      ? moment(this.ends_at).format("YYYY-MM-DD")
      : null

    this.permissionPrefix = "maintenance"

    this.zipcity = this.customer?.getZipCity()
  }

  getTitle() {
    return `Contrat n° ${this.humanId}`
  }

  getStartDate() {
    return formatDate(this.starts_at)
  }

  getStatusLabel() {
    return STATUS_LABELS[this.status]
  }

  set customer(value) {
    if (!this._customer) {
      this._customer = new Customer(value)
    }
  }

  get customer() {
    return this._customer
  }
}
